/* eslint-disable camelcase */
import type { TrackingData } from '@hubcms/domain-tracking';

import type { TeaserLinkUtmData } from './create-teaser-link-utm-params';

export function mapTeaserLinkUtmParams(data?: TrackingData | null): TeaserLinkUtmData {
  return {
    utm_campaign: data?.utm_campaign || '',
    utm_content: data?.utm_content || '',
    utm_medium: data?.utm_medium || '',
    utm_source: data?.utm_source || '',
    utm_term: data?.utm_term || '',
  };
}
